/* Header.css */

.navbar {
  display: flex;
  align-items: center;
  max-width:100%;
}

.menu-icon {
  cursor: pointer;
  margin-left: 10px;
  margin-right:105px;
}
.notification{
  margin-right: 10px;
  justify-content: space-between;
  cursor:pointer;
}
.user{
  margin-left:5px;
  cursor: pointer;
  color: #fff;
}
.name{
  margin-right:auto;
  font-size: 30px;
  font-weight: 800;
  color: #066333;
  margin-left:-70px;

  
}

.nav-links {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 60px; /* Adjust the top position as needed */
  left: 0;
  background-color:#bfbfba; /* Background color of the menu */
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Optional: Add a box shadow for a subtle effect */
}

.nav-links a {
  text-decoration: none;
  color:rgb(28, 7, 7);
  font-weight: 600;
  padding: 10px;
}

.nav-links a:hover {
  background-color: #f1f1f1; /* Optional: Add a background color on hover */
}
.navbar-container{
  background-color:#060600;
  max-width:100%;
 
}
.logo5{
  width: 100px;
}


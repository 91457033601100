/* Footer.css */

.footer {
  background-color: #4c4c4b;
  color: white;
  padding: 20px 0;
  height: auto; /* Increase the height */
  text-align: center; /* Center the content */
  margin-top: 70px;
}

.footer-content {
  display: flex;
  flex-wrap: wrap; /* Allow content to wrap to the next line */
  justify-content: space-around;
  align-content: center;
}

.footer-section {
  padding: 30px;
  max-width: 200px;
  margin: 10px; /* Add margin between sections */
}

.footer-section p {
  margin: 5px 0; /* Add margin to paragraphs within sections */
}

.footer-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}
.footer-bottom span{
  text-align: center;
  margin-right: 70px;
  color:rgb(208, 219, 215);
}

.footer-logo {
  max-width: 100px;
  height: auto;
}

/* Media Query for smaller screens */
@media (max-width: 768px) {
  .footer-content {
    flex-direction: column; /* Arrange sections vertically */
    align-items: center; /* Center sections */
  }

  .footer-section {
    max-width: none; /* Remove max-width on smaller screens */
  }
}

  
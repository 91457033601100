

.selectedImage {
    margin-top: 30px;
    margin-bottom: 30px;
    width: 200px;
    height: 200px;
    object-fit: contain;
}

.giftcard_container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
}

.giftcardItem {
    width: 48%;
    margin-top: 5px;
    margin-bottom: 5px;
    padding: 10px;
    border: 3px solid #ccc;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.selected {
    background-color: grey;
}

.currency_img {
    width: 20px;
    height: 20px;
    margin-right: 5px;
}

.giftcard_text {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.giftcard_usd {
    color: black;
    margin-right: 5px;
}

.top {
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.logo {
    width: 100px;
    height: 100px;
}

.avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
}

.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 90%;
    justify-content: space-between;
}

.select {
    width: 90%;
    height: 60px;
    border-radius: 10px;
    border: 1px solid #B7DF84;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.select_img {
    width: 53px;
    height: 41px;
    padding: 10px;
    margin-left: 25px;
}

.select_text {
    font-size: 12px;
    font-weight: 600;
    color: grey;
    padding: 15px;
}

.warning {
    font-size: 12px;
    font-weight: 600;
    padding: 15px;
    color: red;
}

.dropdown_wrapper {
    width: 90%;
    padding: 12px;
    border-radius: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #e7e7ed;
    margin-top: 12px;
}

.dropdown_item {
    width: 90%;
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom-width: 1px;
    border-bottom-color: #e7e7ed;
}

.bills_container {
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
}

.bill {
    width: 28%;
    height: 120px;
    border-radius: 6px;
    background-color: #F0F1F4;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    margin-right: 10px;
}

.bill_active {
    width: 30%;
    height: 120px;
    border-radius: 6px;
    background-color: #4C9049;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    margin-right: 10px;
}

.bill_text {
    font-size: 12px;
    font-weight: 500;
    color: #A6AAB4;
}

.bill_text_active {
    font-size: 12px;
    font-weight: 500;
    color: #FFFFFF;
}

.bill_img {
    width: 25px;
    height: 25px;
    margin-bottom: 10px;
}

.drop {
    width: 90%;
    height: 60px;
    border-radius: 10px;
    border: 1px solid #B7DF84;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    margin-top: 25px;
}

.drop_list {
    width: 90%;
    border-radius: 10px;
    background-color: white;
    padding: 20px;
    margin-top: 12px;
    border: 1px solid #e7e7ed;
}

.drop_list_item {
    height: 35px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 12px;
}

.drop_list_item_ACTIVE {
    height: 35px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 12px;
    background-color: #B7DF8436;
}

.currency_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 15px;
    margin-top: 15px;
}

.currency_wrapper {
    border: 1px solid #B7DF84;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    width: calc((85% * 100vw) / 3);
    height: 60px;
}

.currency {
    font-size: 12px;
    font-weight: 600;
    color: #5A5A5A;
}

.currency_active {
    background-color: grey;
    opacity: 0.5;
}

.upload {
    width: 90%;
    height: 53px;
    border: 1px dashed #B7DF84;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

.upload_text {
    font-size: 12px;
    font-weight: 500;
    color: #D9D9D9;
}

.selectText {
    color: grey;
    font-size: 14px;
}

.giftcardContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.selectedGiftcard {
    border-color: green;
}

.giftcardImg {
    width: 100px;
    height: 100px;
    margin-bottom: 5px;
}

.giftcardName {
    text-align: center;
    font-size: 14px;
    /* Add any other styling as needed */
  }
  
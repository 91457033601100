/* Assuming you name the file bills.css */

.body {
  display: flex;
  flex-direction: column; /* Set to column to display children vertically */
  height: 932px;
  padding-bottom: 50px; /* Added 'px' unit */
  overflow-x: hidden;
  }
  
  .top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  
  .header {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
  }
  
  .select {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 20px;
    padding-left: 20px;
  }
  
  .select_img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
  
  .select_text {
    font-size: 16px;
    color: white;
  }
  
  .dropdown_wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  
  .dropdown_item {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 10px;
  }
  
  .drop {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 20px;
  }
  
  .drop_list {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  
  .drop_list_item {
    cursor: pointer;
    margin-bottom: 10px;
  }
  
  .drop_list_item_ACTIVE {
    cursor: pointer;
    margin-bottom: 10px;
    font-weight: bold;
  }
  
  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  p {
    margin-bottom: 10px;
    font-size: 14px;
    color: #9D9D9D;
  }
  
  .modal-container {
    flex: 1;
    justify-content: flex-end;
    margin-top: 30%;
    align-items: center;
    background-color: white;
    max-height: 30%;
  }
  
  .close-button-container {
    margin-top: 20px;
  }
  
  .close-button {
    background-color: grey;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
  }
  
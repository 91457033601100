.body {
  display: flex;
  flex-direction: column; /* Set to column to display children vertically */
  height: 932px;
  padding-bottom: 50px; /* Added 'px' unit */
  overflow-x: hidden;
  }
  
  .top {
    display: flex;
    width: 90%;
    justify-content: space-between;
    align-items: center;
  }
  
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
  }
  
  .tabs {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    margin-bottom: 20px;
  }
  
  .tab {
    width: calc(90% / 5);
    display: flex;
    align-items: center;
    padding: 12px 0;
    border-bottom: 1px solid #A6AAB4;
  }
  
  .tab_active {
    width: calc(90% / 5);
    display: flex;
    align-items: center;
    padding: 12px 0;
    border-bottom: 2px solid #B7DF84;
  }
  
  .tab_text_active {
    font-size: 12px;
    font-weight: 600;
    color: #5A5A5A;
  }
  .warning{
    color: red;
  }
  
  .tab_text_inactive {
    font-size: 12px;
    font-weight: 500;
    color: #A6AAB4;
  }
  
  .data_bundle_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 40px;
  }
  
  .data_bundle_item {
    width: calc(90% / 3.3);
     /* Default border for non-selected items */
    cursor: pointer;
  }
  
  .data_bundle_item_v1 {
    background-color: #B7DF84;
    padding: 10px 0;
    width: 100%;
    border-top-right-radius: 13px;
    border-top-left-radius: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid  #B7DF84;
  }
  
  .data_bundle_item_v2 {
    width: 100%;
    height: 80px;
    border-bottom-right-radius: 13px;
    border-bottom-left-radius: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .data_bundle_item.selected {
    border: 2px solid  #B7DF84; /* Border for selected item */
    opacity: 0.7; /* Adjust the transparency for selected item */
  }
  
  .data_price.selected,
  .data_bundle.selected,
  .data_duration.selected {
    font-weight: bold; /* Add any additional styling for selected item */
  }
  
  .data_price {
    font-size: 14px;
    font-weight: 600;
    color: #5A5A5A;
  }
  
  .data_bundle {
    font-size: 10px;
    font-weight: 600;
    margin-bottom: 7px;
    margin-right: 10px;
  }
  
  .data_duration {
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 7px;
  }
  
  .drop {
    width: 90%;
    height: 60px;
    border-radius: 10px;
    border-width: 1px;
    border-color: #B7DF84;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    margin-top: 15px;
    margin-bottom: 20px;
  }
  
  .drop_list {
    width: 90%;
    border-radius: 10px;
    background-color: white;
    padding: 20px;
    margin-top: 12px;
    border-width: 1px;
    border-color: #e7e7ed;
  }
  
  .drop_list_item {
    height: 35px;
    display: flex;
    align-items: center;
    padding-left: 12px;
  }
  
  .drop_list_item_ACTIVE {
    height: 35px;
    display: flex;
    align-items: center;
    padding-left: 12px;
    background-color: #B7DF8436;
  }
  
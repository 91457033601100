    .closeButtonContainer {
    align-items: flex-end;
    padding: 10px;
  }
  
  .closeButton {
    background-color: #b7df84;
    padding: 10px;
    border-radius: 5px;
  }
  
  .closeButtonText {
    color: #fff;
    font-size: 16px;
    font-weight: bold;
  }
  
  .welcome_back {
    font-size: 16px;
    font-weight: 500;
    color: #47924b;
    margin-bottom: 5px;
  }
  
  .user {
    font-size: 20px;
    font-weight: 600;
    color: #5a5a5a;
  }
  
  .logo {
    width: 100px;
    height: 100px;
  }
  
  .first_view {
    display: flex;
    width: 90%;
    justify-content: space-between;
    align-items: center;
  }
  
  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
  }
  
  .wallet {
    width: 72%;
    height: 190px;
    background-color: #81ab4c;
    padding-left: 19px;
    padding-bottom: 22px;
    justify-content: flex-end;
    border-radius: 10px;
  }
  
  .balance_title {
    font-size: 20px;
    font-weight: 500;
    color: #fff;
    margin-bottom: 10px;
  }
  
  .balance {
    font-size: 28px;
    font-weight: 600;
    color: #fff;
  }
  
  .plus_wrapper {
    height: 188px;
    width: 15%;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
  }
  
  .tabs {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    margin-bottom: 20px;
    border-bottom: 1px solid #e7e7ed;
  }
  
  .tab {
    width: 30%;
    align-items: center;
    padding: 12px 0;
    border-bottom: 1px solid #a6aab4;
  }
  
  .tab_active {
    width: 30%;
    align-items: center;
    padding: 12px 0;
    border-bottom: 2px solid #b7df84;
  }
  
  .tab_text_active {
    font-size: 12px;
    font-weight: 600;
    color: #5a5a5a;
  }
  
  .tab_text_inactive {
    font-size: 12px;
    font-weight: 500;
    color: #a6aab4;
  }
  
  .transaction_details {
    font-size: 12px;
    font-weight: 500;
    color: #757f8c;
    margin-bottom: 12px;
  }
  
  .summar_wrapper {
    padding: 20px;
    width: 90%;
    align-items: center;
    border: 1px solid #b7df84;
    border-radius: 10px;
  }
  
  .summar_wrapper_row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  
  .summar_wrapper_row_key {
    font-size: 10px;
    font-weight: 500;
    color: #5a5a5a;
  }
  
  .summar_wrapper_row_value {
    font-size: 10px;
    font-weight: 500;
    color: #5a5a5a;
    margin-vertical: 14px;
  }
  
  .input {
    width: 90%;
    height: 56px;
    border-radius: 7px;
    border: 1px solid #a6aab4;
    padding-left: 16px;
    margin-top: 20px;
    margin-bottom: 40px;
  }
  
  .pay_row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    margin-bottom: 30px;
  }
  
  .credit_card {
    width: 144px;
    height: 120px;
    background-color: #338247;
    border-radius: 10px;
    padding-left: 10px;
    justify-content: space-evenly;
  }
  
  .credit_card_name {
    font-size: 13px;
    font-weight: 600;
    color: #fff;
  }
  
  .card_number {
    font-size: 20px;
    font-weight: 400;
    color: #fff;
  }
  
  .cvv {
    font-size: 12px;
    font-weight: 400;
    color: #fff;
  }
  
  .add_new_card {
    width: 144px;
    height: 120px;
    border-radius: 10px;
    border-style: dashed;
    border-width: 1px;
    border-color: #e7e7ed;
    justify-content: center;
    align-items: center;
  }
  
  .add_plus {
    width: 40px;
    height: 40px;
    background-color: #b7df84;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .add_plus_text {
    font-size: 14px;
    font-weight: 500;
    color: #a6aab4;
  }
  
  .verify_bvn {
    font-size: 13px;
    font-weight: 700;
    color: #9d9d9d;
    text-align: center;
    margin-top: 20px;
  }
  
  .verify_bvn2 {
    font-size: 13px;
    font-weight: 700;
    color: #4c9049;
  }
  
  .bankHeader {
    font-size: 20px;
    font-weight: 500;
    color: #757f8c;
    margin-vertical: 10px;
    margin-bottom: 20px;
  }
  
  .bankDetail_container {
    border-width: 1px;
    border-color: #b7df84;
    border-radius: 7px;
  }
  
  .bankDetail_row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    margin-vertical: 12px;
    padding-horizontal: 10px;
  }
  
  .bankDetail {
    font-size: 15px;
    font-weight: 500;
  }
  
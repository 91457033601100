body{
  background: #2b2b29;
}
 .welcome_back {
  font-size: 10px;
  font-weight: 500; 
  margin-top: 50px; /* Added 'px' unit */
  margin-right: 20px; /* Adjust the margin as needed */
}
.download{
  padding: 30px;
}

 .username1 {
  font-size: 30px; /* Added 'px' unit */
  font-weight: 700;
  margin-right: 20px;
  color: white; /* Adjust the margin as needed */
}
h1,h2,h3,h4 {
  color: white;
}

 .leftContainer {
    flex-Direction: 'column';
}
 .rightContainer {
    flex-Direction: 'column';
    align-Items: 'center';
}
  
 .items {
    font-size: 10;
    color: grey;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25;
    width: 90%;
    border-bottom: 1px solid #e7e7ed;
    padding-bottom: 12;
  }
  
 .modalHeaderText {
    color: grey;
    font-size: 24;
    font-weight: bold;
    text-align: center;
    margin-top: 20;
    margin-bottom: 20;
  }
  
 .modalContainer {
    flex: 1;
    justify-content: flex-end;
    margin-top: 75%;
    align-items: center;
    background-color: white;
    max-height: 50%;
  }
  
 .modalContent {
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    width: 300px;
  }
  
 .closeButton {
    margin-top: 20px;
    background-color: blue;
    padding: 10px;
    border-radius: 5px;
    align-items: center;
  }
  
 .validate {
    margin-bottom: 20px;
  }
  
 .top {
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  
 .header {
    display: flex;
    align-items: center;
    width: 90%;
    justify-content: space-between;
  }
  
 .select {
    width: 90%;
    height: 60px;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid #B7DF84;
    margin-top: 20px;
  }
  
 .select_img {
    width: 53px;
    height: 41px;
    margin-right: 25px;
  }
  
 .select_text {
    font-size: 17px;
    font-weight: 600;
    color: #5A5A5A;
  }
  
 .dropdown_wrapper {
    width: 90%;
    padding: 12px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #e7e7ed;
    margin-top: 12px;
  }
  
 .dropdown_item {
    width: 90%;
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid #e7e7ed;
  }
  
 .dropdown_item_ACTIVE {
    height: 35px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 12px;
    background-color: #B7DF8436;
  }
  
  /* Bills */
 .bills_container {
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
  }
  
 .bill {
    width: 28%;
    height: 120px;
    border-radius: 6px;
    background-color: #F0F1F4;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
 .bill_active {
    width: 30%;
    height: 120px;
    border-radius: 6px;
    background-color: #4C9049;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
 .bill_text {
    font-size: 14px;
    font-weight: 500;
    color: #A6AAB4;
  }
  
 .bill_text_active {
    font-size: 14px;
    font-weight: 500;
    color: #FFFFFF;
  }
  
 .bill_img {
    width: 26px;
    height: 28px;
    margin-bottom: 10px;
  }
  
 .drop {
    width: 90%;
    height: 60px;
    border-radius: 10px;
    border: 1px solid #B7DF84;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    margin-top: 25px;
  }
  
 .drop_list {
    width: 90%;
    border-radius: 10px;
    background-color: white;
    padding: 20px;
    margin-top: 12px;
    border: 1px solid #e7e7ed;
  }
  
 .drop_list_item {
    height: 35px;
    display: flex;
    align-items: center;
    padding-left: 12px;
  }
  
 .drop_list_item_ACTIVE {
    height: 35px;
    display: flex;
    align-items: center;
    padding-left: 12px;
    background-color: #B7DF8436;
  }
  
 .logo2 {
    width: 300px;
    height: 300px;
}
 .first_view {
  display: flex;
  flex-direction: column; /* Align children vertically */
  justify-content: flex-start; /* Align children to the start (left) */
  align-items: flex-start; /* Align children horizontally to the start (left) */
}
.wallet {
  width: 280px;
  margin-right:100%;
  padding: 10px;
}

 .wallet-container {
  display: flex;
  justify-content: space-between;
  background: linear-gradient(to right,#B7DF84, #338247, #066333);
    border-radius: 10px;
    position: 'absolute';
    height: 200px; 
    margin-right: 70px;
    margin-top: -5px;
    margin-bottom: 20px;
}

.balance_title {
  color: #FFF;
  font-family: Outfit;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.8px;
  margin: 0;
  padding: 100px 5px 15px 12px;
  font-weight: bold;
}
.balance {
  color: #FFF;
  font-family: Outfit;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 1.12px;
  padding: 0 5px 60px 12px;
  left: 10px;
  font-weight: bold;
}

.third_view {
    margin-Bottom: 20;
}
.transaction-container {
  display:flex;
  justify-content: space-between;
}
.floating-text {
  top: 50%;
  left: -100%; /* Start the text off-screen to the left */
  white-space: nowrap;
  font-size: 18px;
  color: white;
  margin-bottom: 60px;
  margin-top: 50px;
}

.t-container {
  display:flex;
  justify-content: space-between;
}
.recent_transactions {
    font-Size: 60;
    font-Weight: '600';
    color: white;
}
.classict {
  margin-top: 60px;
  align-content: center;
  border: solid 1px green;
  padding: 30px;
  justify-content: space-between;
}
.see_more {
    font-Size: 15;
    font-Weight: '600';
    color: '#5A5A5A';
}
.transaction {
    margin-Bottom: 25;
    
}
.transaction_img {
    width: 30;
    height: 30;
    margin-Right: 12;
}
.transaction_name {
    font-Size: 16;
    font-Weight: '500';
    margin-Bottom: 10;
    max-Width: 0.6 * width;
}
.time {
    font-Size: 12;
    font-Weight: '500';
    color: '#989898';
}
.amount {
    font-Size: 16;
    font-Weight: '500';
    color: '#989898';
}
/* Add your existing styles here */

/* Styles for the scrollable navigation bar */
.services-container {
  display: flex;
  flex-wrap: wrap;
  border: solid 1px green;
  padding: 30px;
  justify-content: space-between;
  margin: 10px;
  border-radius: 5px;
  margin-bottom: 60px;
}
.container {
margin-top: 120px;
}

.service-item {
  flex: 0 0 calc(33.33% - 20px); /* Adjust the width and margin as needed */
  margin-bottom: 20px; /* Adjust the margin as needed */
  box-sizing: border-box;
  cursor: pointer;
  transition: opacity 0.3s ease-in-out;
}

.service-item:hover {
  opacity: 0.8;
}

/* Your existing styles for service */
.service {
  text-align: center;
}

.service_icon_wrapper {
  margin-bottom: 5px;
  height: 20px;
}

.service_name {
  font-size: 14px;
}

.wallet{
  width:50px;
}


.plus {
  display: inline-flex;
  padding: 82px 10px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 10px;
  background: #5A5A5A;
  margin-left: 250px;
  cursor: pointer;
}

.new{
  width: 45.011px;
height: 28.768px;
flex-shrink: 0;
stroke-width: 1.984px;
stroke: white;
}

.reset{
  font-Size: 16;
    font-Weight: '500';
}
.amount {
  /* Default styles */
  color: black;
}

.amount.red {
  color: red;
}

.amount.green {
  color: green;
}

.amount.orange {
  color: orange;
}

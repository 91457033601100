/* Tv.css */

/* Container styles */
.tv-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    min-height: 100vh;
    padding-bottom: 40px;
  }
  
  /* Header styles */
  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  
  /* Select styles */
  .select {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 50px;
    padding-left: 20px;
  }
  
  .select_img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
  
  .select_text {
    font-size: 16px;
    color: white;
  }
  
  /* Dropdown styles */
  .dropdown_wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  
  .dropdown_item {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 10px;
  }
  
  /* Tabs styles */
  .tab {
    width: calc(90% / 5);
    display: flex;
    align-items: center;
    padding: 12px 0;
    border-bottom: 1px solid #A6AAB4;
  }
  
  .tab_active {
    width: calc(90% / 5);
    display: flex;
    align-items: center;
    padding: 12px 0;
    border-bottom: 2px solid #B7DF84;
  }
  
  .tv_plans_wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .tab_text_active {
    font-size: 12px;
    font-weight: 600;
    color: #5A5A5A;
  }
  
  .warning {
    color: red;
  }
  
  .tab_text_inactive {
    font-size: 12px;
    font-weight: 500;
    color: #A6AAB4;
  }
  
  /* Offer styles */
  .data_bundle_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 40px;
  }
  
  .education_bundle_item {
    width: 170px;
    cursor: pointer;
  }
  
  .education_bundle_item_v1 {
    background-color: #B7DF84;
    padding: 10px 0;
    width: 170px;
    border-top-right-radius: 13px;
    border-top-left-radius: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #B7DF84;
  }
  
  .education_bundle_item_v2 {
    width: 170px;
    height: 80px;
    border-bottom-right-radius: 13px;
    border-bottom-left-radius: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .data_bundle_item.selected {
    border: 2px solid #B7DF84;
    opacity: 0.7;
  }
  
  .data_price.selected,
  .data_bundle.selected,
  .data_duration.selected {
    font-weight: bold;
  }
  
  .data_price {
    font-size: 16px;
    font-weight: 600;
    color: #5A5A5A;
  }
  
  .data_bundle {
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 7px;
    margin-right: 10px;
  }
  
  .data_duration {
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 7px;
  }
  
  .offer_name {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 7px;
    margin-right: 10px;
  }
  
  .offer_amount {
    font-size: 14px;
    font-weight: 600;
    color: #5A5A5A;
  }
  
/* Contact.css */

.contact-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    padding-bottom: 50px;
    min-height: 100vh;
  }
  
  .dark-mode {
    /* Add dark mode styles here */
  }
  
  .top {
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    /* background-color: purple; */
    margin-bottom: 20px;
  }
  
  .logo {
    width: 100px;
    height: 100px;
  }
  
  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 90%;
  }
  
  .top1 {
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 40px;
    font-weight: 600;
    color: #9D9D9D;
    margin-bottom: 40px;
  }
  
  .lorem {
    font-size: 13px;
    font-weight: 600;
    color: #9D9D9D;
    margin-top: 20px;
    margin-bottom: 40px;
  }
  
  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  /* You may need to add more specific styles based on your design requirements */
  
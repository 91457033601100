
/* Add a new class to control the image width */
.login-image {
  width: 100%; /* Make the image take up the full width of its container */
  max-width: 100%; /* Ensure the image does not exceed its original size */
  height: 700px; /* Maintain the image's aspect ratio */
}

.logo1 {
  width: 100%;
  margin-bottom: -20px;
  align-content: center;
  margin-left: 10%;
  margin-right: 20%;
}

.forgot-password {
  color: #FFF;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.715px;
  text-align: end;
  margin-bottom: 40px;
}

.t-wrapper {
  color: #b7df84;
  font-size: 14px;
  text-align: center;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0 5%;
}
.t3{
  margin: 30px;
}
.t1{
  margin: 10px;
  margin-left: 30px;
}
.t2{
  margin: 30px;
}
.btn{
  margin: 0 auto;
}
 
  .profile {
    font-size: 20px;
    font-weight: 600;
    color: #5A5A5A;
  }
  
  .profile_pic {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
  }
  
  .username {
    font-size: 22px;
    font-weight: 600;
    color: #5A5A5A;
    margin-bottom: 7px;
  }
  
  .edit_profile_btn {
    width: 122px;
    height: 40px;
    border-radius: 9px;
    align-items: center;
    justify-content: center;
    background-color: #269C48;
    cursor: pointer;
    margin: 20px;
  }
  
  .edit_profile {
    font-size: 15px;
    font-weight: 600;
    color: #FFFFFF;
    text-align: center;
    padding: 10px;
  }
  
  .refferal {
    font-size: 14px;
    font-weight: 600;
    color: #9D9D9D;
    margin-bottom: 10px;
  }
  .icon {
    margin: 20px;
  }
  
  .refferal_code {
    font-size: 17px;
    font-weight: 600;
    color: #06138c;
    cursor: pointer;
    text-decoration: underline;
  }
  
  .tabs {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    margin-top: 20px;
    border-bottom: 1px solid #e7e7ed;
    margin-bottom: 20px;
  }
  
  .tab {
    width: 30%;
    align-items: center;
    padding: 12px 0;
    border-bottom: 1px solid #A6AAB4;
  }
  
  .tab_active {
    width: 30%;
    align-items: center;
    padding: 12px 0;
    border-bottom: 2px solid #B7DF84;
    cursor: pointer;
  }
  
  .tab_text_active {
    font-size: 12px;
    font-weight: 600;
    color: #5A5A5A;
    cursor: pointer;
  }
  
  .tab_text_inactive {
    font-size: 12px;
    font-weight: 500;
    color: #A6AAB4;
    cursor: pointer;
  }
  
  .rows {
    display: flex;
    align-items: flex-start;
    width: 90%;
    border-bottom: 1px solid #e7e7ed;
    padding-bottom: 10px;
    margin-bottom: 20px;
  }
  
  .t1 {
    font-size: 14px;
    font-weight: 600;
    color: #9D9D9D;
    margin-bottom: 5px;
  }
  
  .t2 {
    font-size: 17px;
    font-weight: 600;
    color: #5A5A5A;
  }
  
  .preference_row {
    width: 90%;
    padding-bottom: 20px;
    border-bottom: 1px solid #e7e7ed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;
    cursor: pointer;
  }
  
  .preference_img {
    width: 30px;
    height: 30px;
    cursor: pointer;
    margin-right: 30px;
  }
  
  .preference_text {
    font-size: 20px;
    font-weight: 600;
    color: white;
    cursor: pointer;
  }
  
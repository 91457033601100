.textfield {
  display: flex;
  align-items: center;
  margin-bottom: 50px;
  border: 1px solid #B7DF84;
  border-radius: 4px;
  height:95px;
  padding:10px;
  margin-top: 30px;



}

.icon_wrapper {
  display: flex;
  align-items: center;
  padding: 20px;
  border-right: 1px solid #B7DF84;
}

.input_wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.input {
  width: 100%;
  height: 45px;
  border: none;
  outline: none;
  padding: 10px; /* Add padding to match the title and error styles */
}

.title {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 3px;
  color: white;
}

.error {
  color: red;
  font-size: 10px;
  font-weight: 400;
  margin-top: 5px;
  padding: 10px;
}

.btn {
  margin-top: 50px;
  width: 368px;
  height: 55px;
  flex-shrink: 0;
  border-radius: 15px 5px;
  background: linear-gradient(270deg, #066333 0%, #338247 78.09%, #B7DF84 107.2%);
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
 .loadingText {
    color: rgba(255, 255, 255, 0.90);
    font-family: Inter;
    font-size: 19px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 1.615px;
  }
.body {
    width: 100%;
    display: flex;
    align-items: center;
    background-color: white;
    min-height: 100%;
}

.items {
    font-size: 20px;
    color: grey;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;
    width: 90%;
    border-bottom-width: 1px;
    border-bottom-color: #e7e7ed;
    padding-bottom: 12px;
}

.paginationContainer {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.top {
    width: 90%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    /* background-color: purple; */
}

.modalContainer {
    flex: 1;
    justify-content: center;
    height: 30%;
    width: 80%;
    align-items: center;
    background-color: white;
    margin-top: 10%;
}

.modalContent {
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    width: 50%px;
}

.closeButton {
    width: 60px;
    background-color: green;
    padding: 10px;
    margin: 20px;
    border-radius: 5px;
    align-items: center;
}

.closeButtonp {
    color: white;
    font-weight: bold;
}

.Previous {
    width: 100px;
    height: 40px;
    background-color: lightgray;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    color:white;
    padding: 10px;
    margin: 5px;
    margin-left: auto;
    margin-bottom: 20px;
}

.Next {
    width: 100px;
    height: 40px;
    background-color: lightgray;
    justify-content: center; 
    border-radius: 5px;
    padding: 10px;
    color: white;
    margin: 5px;
    margin-right: auto;
    margin-bottom: 20px;
}

.paginationButtonp {
    color: white;
    font-weight: bold;
    align-items: center;
}

.logo {
    width: 100px;
    height: 100px;
}

.avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
}

.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 90%;
    justify-content: space-between;
    margin-bottom: 30px;
}

.transaction {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;
    width: 90%;
    border-bottom-width: 1px;
    border-bottom-color: #e7e7ed;
    padding-bottom: 12px;
}

.transaction_img {
    width: 30px;
    height: 30px;
    margin-right: 12px;
}

.transaction_name {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
    max-width: 60%;
}

.time {
    font-size: 12px;
    font-weight: 500;
}

.amount {
    /* Default styles */
    color: black;
  }
  
  .amount.red {
    color: red;
  }
  
  .amount.green {
    color: green;
  }
  
  .amount.orange {
    color: orange;
  }
  

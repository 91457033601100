/* Otp.css */

/* Container styles */
.body {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
  }
  
  /* Reset margin for all paragraphs */
  .body p {
    margin: 0;
  }
  
  /* OTP input styles */
  .body input {
    width: calc((90% - 40px) / 4);
    height: 55px;
    color: #5A5A5A;
    border-radius: 5px;
    border-width: 1px;
    border-color: #B7DF84;
    padding-left: calc((((90% - 40px) / 4) / 2) - 5px);
    font-size: 16px;
    margin-right: 10px;
  }
  
  /* Resend button styles */
  .body button {
    cursor: pointer;
    background-color: #066333;
    color: #fff;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 600;
    opacity: 1;
  }
  
  .body button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  /* Submit button styles */
  .Btn {
    margin-top: 20px;
  }
  
  /* Timer styles */
  .body p.timer {
    font-size: 14px;
    font-weight: 500;
    color: #464646;
    margin-top: 25px;
  }
  
  /* Header styles */
  .body p.header {
    font-size: 18px;
    font-weight: 500;
    color: #5A5A5A;
  }
  
  /* Text styles */
  .body p.text {
    font-size: 14px;
    font-weight: 400;
    color: #5A5A5A;
    margin-bottom: 20px;
  }
  
  /* Email styles */
  .body p.email {
    font-size: 14px;
    font-weight: 600;
    color: #066333;
  }
  
  /* Container styles */
  .body div.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
  }
  
  /* Margin styles */
  .body div.margin {
    margin: 50px;
  }
  
  /* Footer styles */
  .Footer {
    margin-top: 50px;
  }
  
  /* Loading spinner styles (replace with your actual spinner styles) */
  .Btn.loading {
    background-color: transparent;
    border: none;
  }
  
  /* Error alert styles */
  .Alert {
    margin-top: 20px;
    background-color: #f8d7da;
    color: #721c24;
    border-color: #f5c6cb;
    padding: 15px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 500;
  }
  
  /* Dark mode styles (replace with your actual dark mode styles) */
  body[data-theme="dark"] {
    background-color: #121212;
    color: #fff;
  }

.modalContainer1 {
    flex: 1;
    justify-content: center;
    height: 20%;
    width: 20%;
    align-items: center;
    background-color: white;
    margin-top: 10%;
}
  
  /* Add more styles as needed */
  
.body {
    display: flex;
    align-items: center;
    background-color: white;
    padding-bottom: 50px;
    min-height: 100%;
    
  }
  
  .top {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  
  .logo {
    width: 100px;
    height: 100px;
  }
  
  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  
  .lorem {
    font-size: 13px;
    font-weight: 600;
    color: #9D9D9D;
    margin-top: 20px;
    margin-bottom: 40px;
  }
  
  .notificationContent {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .notificationItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    border-bottom-width: 1px;
    border-bottom-color: #ccc;
  }
  
  .notificationItemlogo {
    width: 50px;
    height: 50px;
    margin-right: 16px;
  }
  
  .notificationp {
    flex: 1;
  }
  
  .subject {
    font-size: 18px;
    font-weight: bold;
    color: #888;
    margin-bottom: 16px;
  }
  
  .message {
    margin-right: 16px;
    font-size: 14px;
    color: #888;
    margin-bottom: 16px;
  }
  
  .createdAt {
    margin-right: 17px;
    font-size: 10px;
    color: #888;
  }
  
  .headerp {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 30px;
    color: grey;
  }
  
  .refreshButton {
    background-color: green;
    width: 79px;
    
    padding: 10px;
    margin-left: 10px;
    border-radius: 5px;
    align-self: center;
    margin-top: 10px;
    margin-bottom: 20px;
  }
  
  .refreshButtonp {
    color: white;
    font-weight: bold;
    text-align: center;
  }
  
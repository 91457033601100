
  
  .top {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    /* background-color: purple; */
  }
  
  .logo {
    width: 100px;
    height: 100px;
  }
  
  .avatar {
    width: 40px;
    height: 40px;
  }
  
  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  
  .refferal_container {
    height: 120px;
    border-width: 1px;
    border-color: #e7e7ed;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-vertical: 30px;
  }
  
  .refferal_amount {
    font-size: 35px;
    font-weight: 600;
    color: 'white';
    margin-bottom: 7px;
  }
  
  .redeems {
    font-size: 14px;
    font-weight: 600;
    color: #269c48;
  }
  
  .info_wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    border-bottom-width: 1px;
    border-bottom-color: #e7e7ed;
    padding-bottom: 40px;
    margin-bottom: 30px;
  }
  
  .info {
    width: 20px;
    height: 20px;
  }
  
  .information {
    font-size: 13px;
    font-weight: 600;
    color: 'white';
  }
  
  .refferal {
    font-size: 14px;
    font-weight: 600;
    color: 'white';
    margin-bottom: 10px;
  }
  
  .refferalby {
    font-size: 16px;
    font-weight: 600;
    color: 'white';
    margin-bottom: 10px;
  }
  
  .refferal_code {
    font-size: 17px;
    font-weight: 600;
    color: 'white';
  }
  
  .works {
    font-size: 13px;
    font-weight: 600;
    color: 'white';
    margin-vertical: 20px;
  }
  
  .step_wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    border-bottom-width: 1px;
    border-bottom-color: #e7e7ed;
    padding-bottom: 40px;
    margin-bottom: 30px;
  }
  
  .tc {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-vertical: 20px;
  }
  
  .tc1 {
    font-size: 13px;
    font-weight: 600;
    color: 'white';
  }
  
  .tc2 {
    font-size: 13px;
    font-weight: 600;
    color: #066333;
  }
  
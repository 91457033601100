/* styles.css */

.body {
    width: 100%;
    display: flex;
    align-items: center;
    background-color: white;
    min-height: 100vh;
  }
  
  .logo {
    width: 312px;
    height: 255px;
  }
  
  .or {
    font-size: 20px;
    font-weight: 600;
    color: #5A5A5A;
    margin-vertical: 30px;
  }
  
  .google_signup {
    width: 90%;
    height: 55px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-width: 1px;
    border-color: #e7e7ed;
    border-radius: 5px;
    box-shadow: 5px 5px grey;
  }
  
  .google_signup_text {
    font-size: 15px;
    font-weight: 500;
    color: #5A5A5A;
    margin-right: 10px;
  }
  
  .google_signup_icon {
    width: 18px;
    height: 18px;
  }
  
  .t-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 35px;
  }
  
  .t1 {
    font-size: 14px;
    font-weight: 400;
    color: #5A5A5A;
  }
  
  .t2 {
    font-size: 14px;
    font-weight: 600;
    color: #066333;
    margin-left: 5px;
    cursor: pointer; 
    text-decoration: underline;/* Add cursor style for indicating clickable */
  }
  
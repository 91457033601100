
  
  .logo-container {
    position: relative;
    width: 100%;
    height:100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background: black;
  }
  
  .green-circle {
    width: 150px; /* Adjust the size of the green circle */
    height: 150px; /* Adjust the size of the green circle */
    background-color: #B7DF84; /* Light green color */
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 3s ease-in-out; /* Adjust the duration of zoom-in/out effect */
  }
  
  .rolling-logo {
    width: 100%; /* Adjust the width of your logo */ /* Adjust the height of your logo */
    transition: opacity 1s ease-in-out;
  }
  
  .loading-logo-animation {
    animation: spin 1.5s linear infinite; /* Rotation continues until the main page is displayed */
  }
  
  .zoom-animation {
    animation: zoom 2s ease-in-out infinite; /* Zooming in and out effect */
  }
  
  @keyframes spin {
    0% { transform: scale(1) rotate(0deg); }
    100% { transform: scale(1) rotate(360deg); }
  }
  
  @keyframes zoom {
    0%, 100% { transform: scale(1); }
    50% { transform: scale(1.2); } /* Adjust the scale factor for the zooming effect */
  }
  
  
/* styles.css */

.body {
    width: 300px;
    display: flex;
    align-items: center;
    background-color: white;
    padding-bottom: 50px;
    min-height: 100%;
  }
  
  .selectedImage {
    margin-top: 30px;
    margin-bottom: 30px;
    width: 200px;
    height: 200px;
    object-fit: contain;
  }
  
  .top {
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  
  .logo {
    width: 100px;
    height: 100px;
  }
  
  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
  }
  
  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 90%;
    justify-content: space-between;
  }
  
  .select {
    width: 90%;
    height: 60px;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-width: 1px;
    border-color: #B7DF84;
    margin-top: 20px;
  }
  
  .select_img {
    width: 53px;
    height: 41px;
    margin-horizontal: 25px;
  }
  
  .dropdown_wrapper {
    width: 90%;
    padding: 12px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 1px;
    border-color: #e7e7ed;
    margin-top: 12px;
  }
  
  .dropdown_item {
    width: 90%;
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom-width: 1px;
    border-bottom-color: #e7e7ed;
  }
  
  .bills_container {
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
  }
  
  .bill {
    width: 160px;
    height: 120px;
    border-radius: 6px;
    background-color: #F0F1F4;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
  }
  
  .bill_active {
    width: 160px;
    height: 120px;
    border-radius: 6px;
    background-color: #4C9049;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    
  }
  
  .bill_p {
    font-size: 14px;
    font-weight: 500;
    color: #A6AAB4;
  }
  
  .bill_p_active {
    font-size: 14px;
    font-weight: 500;
    color: #FFFFFF;
  }
  
  .bill_img {
    width: 26px;
    height: 28px;
    margin: 10px;
  }
  
  .drop {
    width: 90%;
    height: 60px;
    border-radius: 10px;
    border-width: 1px;
    border-color: #B7DF84;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-horizontal: 10px;
    align-items: center;
    margin-top: 25px;
  }
  
  .drop_list {
    width: 90%;
    border-radius: 10px;
    background-color: white;
    padding: 20px;
    margin-top: 12px;
    border-width: 1px;
    border-color: #e7e7ed;
  }
  
  .drop_list_item {
    height: 35px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 12px;
  }
  
  .drop_list_item_ACTIVE {
    height: 35px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 12px;
    background-color: #B7DF8436;
  }
  
  .currency_container {
    display: flex;
    flex-direction: row;
    width: 90%;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 15px;
    margin-top: 15px;
  }
  
  .currency_wrapper {
    border-width: 1px;
    border-color: #B7DF84;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    width: 85px;
    height: 60px;
  }
  
  .currency_img {
    width: 38px;
    height: 20px;
  }
  
  .currency {
    font-size: 18px;
    font-weight: 600;
    color: #5A5A5A;
  }
  
  .currency_active {
    background-color: grey;
    opacity: 0.3;
  }
  
  .upload {
    width: 270px;
    height: 53px;
    border-width: 1px;
    border: 1px solid #B7DF84;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .upload_p {
    font-size: 12px;
    font-weight: 500;
    color: #D9D9D9;
  }

 /* Add these styles to your existing CSS or create a new CSS file */

.giftcard_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.giftcard_item {
  width: 10%; /* Adjust the width as needed based on your design */
  margin-bottom: 20px;
  cursor: pointer;
  padding: 7px;
}

.quantity-container {
  display: flex;
  align-items: center;
}

.quantity-label {
  margin-right: 10px;
}

.quantity-controls {
  display: flex;
  align-items: center;
}

.quantity-button {
  padding: 5px 10px;
  font-size: 16px;
  cursor: pointer;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  margin: 0 5px;
}

.quantity-value {
  margin: 0 10px;
  font-size: 16px;
}


.giftcard_img {
  width: 100%;
  height: auto;
  border-radius: 8px; /* Add border-radius for rounded corners if desired */
}

.giftcard_item.selected {
  opacity: 0.7; /* Adjust the opacity value as needed */
}

.giftcard_name {
  text-align: center;
  margin-top: 10px;
  font-size: 14px; /* Adjust font size as needed */
}


.giftcard_container1 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

/* Individual Giftcard Amount Item */
.giftcard_item1 {
  cursor: pointer;
  margin-bottom: 10px;
  padding: 10px;
  border: 2px solid #ccc; /* Border for unselected amounts */
  border-radius: 8px;
  transition: background-color 0.3s ease;
}

/* Selected Giftcard Amount Item */
.giftcard_item1.selected {
  border: 2px solid #25D366; /* Add the desired border style for selected item */
  background-color: #f0f0f0;
  opacity: 0.7; /* Add the desired background color for selected item */
  /* Add other styles as needed */
}

/* Currency and Amount Text */
.giftcard1_text {
  margin-top: 5px;
}

.giftcard_amountitem {
  font-size: 16px;
  font-weight: bold;
  margin: 5px 0;
}

/* Additional styling for responsiveness */
@media (max-width: 1200px) {
  .giftcard_item {
    width: 25%;
  }
}

@media (max-width: 992px) {
  .giftcard_item {
    width: 25%;
  }
}

@media (max-width: 768px) {
  .giftcard_item {
    width: 25%;
  }

  /* For smaller screens, you may want to adjust the number of cards in a row */
  @media (max-width: 576px) {
  .giftcard_item {
    width: 25%;
  }
}
}
  
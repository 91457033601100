/* Add your styles based on the converted React Native styles */
.body {
  display: flex;
  flex-direction: column; /* Set to column to display children vertically */
  height: 932px;
  padding-bottom: 50px; /* Added 'px' unit */
  overflow-x: hidden;
}
  
 .nos {
    height: 52px;
    width: calc((90% / 4.3) - 20px);
    background-color: #eff8e5;
    border-radius: 10px;
    display: flex;
  }
  
 .nos-text {
    font-size: 13px;
    font-weight: 500;
    color: #5a5a5a;
  }
  h1{
    margin-top:20px;
    margin-bottom: 50px;
  }
 .network {
    width: 369px;
    height: 56px;
    flex-shrink: 0;
    fill: #050505;
   stroke-width: 1px;
   stroke: #B7DF84;
   margin-bottom: 50px;
  }

 .prices {
  display: inline-flex;
padding: 16px 20px 9px 16px;
margin: 2px;
align-items: center;
border-radius: 5px;
background: #B7DF84;
margin-bottom:10px;
}
 .prices_container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  row-gap: 10px;
  justify-content: space-between;
  margin-bottom: 30px;
}
  